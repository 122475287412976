.formCell {
  display: flex;
  flex-wrap: wrap;
}
.formCell .el-form-item {
  width: 50%;
  margin-bottom: 0.5rem;
}
.priceManager .itemLabel {
  min-width: 7.5rem;
}
